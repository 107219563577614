import { Suspense, lazy } from 'react';
import {
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
  Outlet,
} from "react-router-dom";
import { SEO, FallBack } from '../lib/components'
import __ from '../lib/gettext';

import ErrorBoundary from './ErrorBoundary';
import { LogInPage } from '../lib/auth';
import Header from './Header';
import Footer from './Footer';
import { GoogleOAuthProvider } from '@react-oauth/google';

import LOGO_MAINTEN from './img/icon-FANSI-maintain.svg';

const router = createBrowserRouter([
  { path: "/*", Component: Root }
]);

const BSCxGrimes = lazy(() => import('./events/BSCxGrimes'));
const Collection = lazy(() => import('./collections/Collection'));
const DjScore = lazy(() => import('./DjScore/'));
const EditList = lazy(() => import('./playList/editList'));
const FaithNomad = lazy(() => import('./events/FaithNomad/'));
const FaithNomadNFT = lazy(() => import('./events/FaithNomad/nftFrame'));
const FNPanel = lazy(() => import('./events/FaithNomad/Panel/'));
const HatchingPlan = lazy(() => import('./hatching/'));
const Home = lazy(() => import('./Home'));
const ImportList = lazy(() => import('./playList/import'));
const Leaderboard = lazy(() => import('./hatching/Leaderboard'));
const MetaBoom = lazy(() => import('./MetaBoom'));
const MiniBoom = lazy(() => import('./MiniBoom'));
const NftLibrary = lazy(() => import('./playList/nftLibrary'));
const PrizePool = lazy(() => import('./events/PrizePool'));
const TenMinsSalvation = lazy(() => import('./events/salvations/TenMinsSalvation'));


const MaintenanceInfo = () => {
  return (
    <div className='absolute-center text-black min-w-[375px]'>
      <img src={LOGO_MAINTEN} className='w-[330px] mx-auto' />
      <p className='mt-6 w-full text-center'>伺服器正在健身中，請您耐心等待</p>
      <div className='bg-white rounded-2xl mt-6 p-6 w-full text-xs lg:text-base'>
        <p>【維護時間】：2025年01月22日(三) 01:00 ~ 03:00</p>
        <p>【維護內容】：系統升級</p>
        <p>【影響範圍】：網站將暫時停止存取，無法造訪或購票。</p>
      </div>
    </div>
  )
}

const App = () => {
  return <RouterProvider router={router} />;
}

function Root() {
  let ServerOnMaintenance = false;
  const curr = Date.now();
  const MaintenStart = new Date('2025-01-22 01:00+08').getTime();
  const MaintenEnd = new Date('2025-01-22 03:00+08').getTime();
  if (curr > MaintenStart && curr < MaintenEnd) ServerOnMaintenance = true;
  if (ServerOnMaintenance)
    return <MaintenanceInfo />

  return (
    <ErrorBoundary>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_API_KEY + ''}>                                      
      <Routes >
        <Route path='/FaithNomadNFT/:id' element={<Suspense fallback={<FallBack />}>
          <FaithNomadNFT /> </Suspense>} />
        <Route path='/MiniBoom/:idNumber' element={<Suspense fallback={<FallBack />}>
          <MiniBoom /> </Suspense>} />
        <Route path='/privacypolicy' element={<PrivacyPolicy />} />
        <Route path='/privacy' element={<AppPrivacy />} />
        <Route path='/tos' element={<TOS />} />
        <Route path='/' element={<Main />}>          
          <Route path='/collection/:id/*' element={<Suspense fallback={<FallBack />}>
            <Collection /></Suspense>} />
          <Route path='/explore/*' element={<Suspense fallback={<FallBack />}>
            <DjScore /> </Suspense>} />
          <Route path='/FaithNomad/Panel/*' element={<Suspense fallback={<FallBack />}>
            <FNPanel /> </Suspense>} />
          <Route path='/FaithNomad/*' element={<Suspense fallback={<FallBack />}>
            <FaithNomad /> </Suspense>} />
          <Route path='/hatching/*' element={<Suspense fallback={<FallBack />}>
            <HatchingPlan /> </Suspense>} />
          <Route path='/Leaderboard' element={<Suspense fallback={<FallBack />}>
            <Leaderboard /> </Suspense>} />
          <Route path='/login/*' element={<LogInPage />} />
          <Route path='/MetaBoom/:idNumber' element={<Suspense fallback={<FallBack />}>
            <MetaBoom /> </Suspense>} />
          <Route path='/NeverForget' element={<Suspense fallback={<FallBack />}>
            <BSCxGrimes /> </Suspense>} />
          <Route path='/playList/import/:idNumber' element={<Suspense fallback={<FallBack />}>
            <ImportList /> </Suspense>} />
          <Route path='/playList/edit/:idNumber' element={<Suspense fallback={<FallBack />}>
            <EditList /> </Suspense>} />
          <Route path='/playList/library' element={<Suspense fallback={<FallBack />}>
            <NftLibrary /> </Suspense>} />
          <Route path='/Prize_Pool' element={<Suspense fallback={<FallBack />}>
            <PrizePool /> </Suspense>} />
          <Route path='/TenMinsSalvation' element={<Suspense fallback={<FallBack />}>
            <TenMinsSalvation /> </Suspense>} />
          <Route path='/' element={<Suspense fallback={<FallBack />}>
            <Home /> </Suspense>} />
          <Route path='*' element={<Suspense fallback={<FallBack />}>
            <Home /> </Suspense>} />
        </Route >
        </Routes >     
      </GoogleOAuthProvider>
    </ErrorBoundary >
  );
}

const Main = () => {

  return (
    <div className='w-full relative min-h-screen pt-12 lg:pt-16 overflow-hidden' >
      <SEO
        title={__('Home_Desc')}
        url={window.location.href}
        description={__('Web_Default_Desc')}>
        <meta property='og:type' content='website' />
        <meta property='fb:app_id' content='575196569931805' />
        <meta property='og:site_name' content='MetaBoom by Fansi' />
        <meta property='og:url' content={'https://metaboom.fansi.me'} />
        <meta property='og:image' content='https://fansi-static.s3.ap-southeast-1.amazonaws.com/MetaBoom/banner/Metaboom_FB.jpg' />
        <meta property='og:image:alt' content={__('Home_Desc')} />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property='og:title' content={__('Home_Desc')} />
        <meta property='og:description' content={__('Web_Default_Desc')} />
        <meta property='og:url' content={window.location.href} />
      </SEO>      
      <Header />
      <Outlet />
      <Footer />
    </div>
  )
}

const TOS = () => (
  <div className='w-full bg-white h-screen p-3 lg:p-6'>
    <h1 className='text-center mb-4'> {__('Terms_of_Service')} </h1>
    <iframe className='w-full ' style={{ height: '90vh' }} title='Terms of Service' src={process.env.PUBLIC_URL + '/legal/terms.html'} />
  </div>
);

const PrivacyPolicy = () => (
  <div className='w-full bg-white h-screen p-3 lg:p-6'>
    <h1 className='text-center mb-4'>{__('Privacy_Policy')}</h1>
    <iframe className='w-full ' style={{ height: '90vh' }} title='Privacy Policy' src={process.env.PUBLIC_URL + '/legal/privacy.html'} />
  </div>
);

const AppPrivacy = () => (
  <div className='w-full h-full bg-white text-black p-6 mx-auto' style={{ maxWidth: 800 }}>
    <p className='font-bold text-2xl'>Privacy Policy</p>
    <p className='mt-4'>
      FANSI built the MetaBoom app as
      a Free app. This SERVICE is provided by
      FANSI at no cost and is intended for use as
      is.
    </p>
    <p className='mt-2'>
      This page is used to inform visitors regarding our
      policies with the collection, use, and disclosure of Personal
      Information if anyone decided to use our Service.
    </p>
    <p className='mt-2'>
      If you choose to use our Service, then you agree to
      the collection and use of information in relation to this
      policy. The Personal Information that we collect is
      used for providing and improving the Service. We will not use or share your information with
      anyone except as described in this Privacy Policy.
    </p>
    <p className='mt-2'>
      The terms used in this Privacy Policy have the same meanings
      as in our Terms and Conditions, which are accessible at
      MetaBoom unless otherwise defined in this Privacy Policy.
    </p>
    <p className='font-bold text-2xl mt-6'>Information Collection and Use</p>
    <p className='mt-4'>
      For a better experience, while using our Service, we
      may require you to provide us with certain personally
      identifiable information, including but not limited to Email, Name, User ID. The information that
      we request will be retained by us and used as described in this privacy policy.
    </p>
    <div className='mt-4'>
      <p className='mt-4'>
        The app does use third-party services that may collect
        information used to identify you.
      </p>
      <p className='mt-2'>
        Link to the privacy policy of third-party service providers used
        by the app
      </p>
      <ul className='mt-4 list-disc pl-8'>
        <li>
          <a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a>
        </li>
        <li>
          <a href="https://www.facebook.com/about/privacy/update/printable" target="_blank" rel="noopener noreferrer">Facebook</a>
        </li>
        <li>
          <a href="https://expo.io/privacy" target="_blank" rel="noopener noreferrer">Expo</a>
        </li>
      </ul>
    </div>

    <p className='font-bold text-2xl mt-6'>Log Data</p>
    <p className='mt-4'>
      We want to inform you that whenever you
      use our Service, in a case of an error in the app
      we collect data and information (through third-party
      products) on your phone called Log Data. This Log Data may
      include information such as your device Internet Protocol
      ("IP") address, device name, operating system version, the
      configuration of the app when utilizing our Service,
      the time and date of your use of the Service, and other
      statistics.
    </p>
    <p className='font-bold text-2xl mt-6'>Cookies</p>
    <p className='mt-4'>
      Cookies are files with a small amount of data that are
      commonly used as anonymous unique identifiers. These are sent
      to your browser from the websites that you visit and are
      stored on your device's internal memory.
    </p>
    <p className='mt-2'>
      This Service does not use these "cookies" explicitly. However,
      the app may use third-party code and libraries that use
      "cookies" to collect information and improve their services.
      You have the option to either accept or refuse these cookies
      and know when a cookie is being sent to your device. If you
      choose to refuse our cookies, you may not be able to use some
      portions of this Service.
    </p>
    <p className='font-bold text-2xl mt-6'>Service Providers</p>
    <p className='mt-4'>
      We may employ third-party companies and
      individuals due to the following reasons:
    </p>
    <ul className='mt-4 list-disc pl-8'>
      <li>To facilitate our Service;</li>
      <li>To provide the Service on our behalf;</li>
      <li>To perform Service-related services; or</li>
      <li>To assist us in analyzing how our Service is used.</li>
    </ul>
    <p className='mt-2'>
      We want to inform users of this Service
      that these third parties have access to their Personal
      Information. The reason is to perform the tasks assigned to
      them on our behalf. However, they are obligated not to
      disclose or use the information for any other purpose.
    </p>
    <p className='font-bold text-2xl mt-6'>Security</p>
    <p className='mt-4'>
      We value your trust in providing us your
      Personal Information, thus we are striving to use commercially
      acceptable means of protecting it. But remember that no method
      of transmission over the internet, or method of electronic
      storage is 100% secure and reliable, and we cannot
      guarantee its absolute security.
    </p>
    <p className='font-bold text-2xl mt-6'>Links to Other Sites</p>
    <p className='mt-4'>
      This Service may contain links to other sites. If you click on
      a third-party link, you will be directed to that site. Note
      that these external sites are not operated by us.
      Therefore, we strongly advise you to review the
      Privacy Policy of these websites. We have
      no control over and assume no responsibility for the content,
      privacy policies, or practices of any third-party sites or
      services.
    </p>
    <p className='font-bold text-2xl mt-6'>Children's Privacy</p>
    <div>
      <p className='mt-4'>
        These Services do not address anyone under the age of 13.
        We do not knowingly collect personally
        identifiable information from children under 13 years of age. In the case
        we discover that a child under 13 has provided
        us with personal information, we immediately
        delete this from our servers. If you are a parent or guardian
        and you are aware that your child has provided us with
        personal information, please contact us so that
        we will be able to do the necessary actions.
      </p>
    </div>
    <p className='font-bold text-2xl mt-6'>Changes to This Privacy Policy</p>
    <p className='mt-4'>
      We may update our Privacy Policy from
      time to time. Thus, you are advised to review this page
      periodically for any changes. We will
      notify you of any changes by posting the new Privacy Policy on
      this page.
    </p>
    <p className='mt-2'>This policy is effective as of 2022-10-01</p>
    <p className='font-bold text-2xl mt-6'>Contact Us</p>
    <p className='mt-2'>
      If you have any questions or suggestions about our
      Privacy Policy, do not hesitate to contact us at <a href="mailto:support@fansi.me">support@fansi.me</a>
    </p>
  </div>
)


export default App;
